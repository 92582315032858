import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Box,
    Flex,
    Text,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';

export default function ProjectsGrid({ projects }) {
  
  const enlargeTitle = {
    hover: {
        scale: '1.05',
    },
    tap: {
        scale: '.95',
    },
    transition: {
        ease: "easeInOut",
        duration: 2.5,
     }

    }

    return (
        <>
            <Flex w="100%" justifyContent="center" flexWrap="wrap" mb="24">
                {projects.map((project, index) => {
                    
                    return (
                        <Box as={motion.div}
                        layout 
                            initial={{opacity: 0}}
                            animate={{ opacity: 1}}
                            exit={{ opacity: 0}}
                            whileHover="hover"
                            whileTap="tap"
                            key={index} flexDirection="column" w={{base: "100%", lg: "30%"}} mb="8" p="2" variants={enlargeTitle} >
                        <LinkBox as={GatsbyLink} to={project?.project1.document.url}>
                                <Box mb="-1">
                                    <GatsbyImage image={getImage(project.project1.document.data.project_images[0].image.localFile)} alt={project.project1.document.data.project_images[0].image.alt} objectFit="cover" style={{height: '100%'}} />
                                </Box>
                                <Flex w="100%" justifyContent="center" position="relative" >
                                        <Box bg="black" display="inline-block" py="2" px="8" textAlign="center" w="100%">
                                        <LinkOverlay>
                                            <Text color="white" fontSize="1.25rem" fontWeight="900" mb="0">{project.project1.document.data.project_title}</Text>
                                            <Text color="gold.500" fontWeight="700" textTransform="uppercase" mb="0" fontSize="0.8rem">View Project</Text>
                                            </LinkOverlay>
                                        </Box>
                                </Flex>
                        </LinkBox>
                        </Box>
                    )
                }
            )}
            </Flex>
        </>
    )
}