import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import { SliceZone } from '@prismicio/react';
import { components } from '../components/Slices';
import Section from '../components/Section/Section';
import PageHeadlineContent from "../components/PageHeadlineContent/PageHeadlineContent";
import ProjectsGrid from "../components/ProjectsGrid/ProjectsGrid";

const OurProjectsPage = ({ data, location }) => {

  const pageContent = data.prismicProjectsPage.data;
  const projects = data.prismicProjectsPage.data.project;

  return (
    <Layout
      footerImage={pageContent.footer_image.localFile}
      location={location}
      headerImage={pageContent.header_image.localFile}
      alt={pageContent.header_image.alt}
      title={pageContent.page_title.text}
    >
      <Section>
        <PageHeadlineContent
          textAlign="center" 
          sectionHeadline={pageContent.page_title}
          sectionText={pageContent.sub_heading.richText}
        />
      </Section>
      <SliceZone 
        slices={pageContent.body}
        components={components}
      />
      <ProjectsGrid 
        projects={projects}
      />
    </Layout>
  )
}

export const query = graphql`
query {
  prismicProjectsPage {
    data {
      sub_heading {
        richText
      }
      page_title
      header_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      project {
        project1 {
          document {
            ... on PrismicProject {
              id
              url
              data {
                project_title
                project_images {
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      footer_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
}
`

export default OurProjectsPage

export const Head = ({ data }) => {
  const pageTitle = data.prismicProjectsPage.data.page_title
  return (
    <title>{`Cloud 9 Builders | ${pageTitle}`}</title>
  )
}
